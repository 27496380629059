import React from "react";
import { RangeSlider } from "flowbite-react";

type PenaltySettingProps = {
  value: number;
  onChange: (value: number) => void;
  label: string;
  spectrumStart: string;
  spectrumEnd: string;
};

function PenaltySettingSpectrum({
  value,
  onChange,
  label,
  spectrumStart,
  spectrumEnd,
}: PenaltySettingProps) {
  return (
    <div className="p-4 border rounded-lg shadow-sm bg-white">
      <label className="text-sm font-semibold text-gray-700">
        {label}: {value}
      </label>
      <div className="flex flex-col items-center gap-2 mt-2">
        <RangeSlider
          min={0}
          max={10}
          step={1}
          value={value}
          onChange={(e) => onChange(parseInt(e.target.value))}
          className="w-full"
        />
        <div className="flex justify-between w-full">
          <span className="text-sm text-gray-700">{spectrumStart}</span>
          <span className="text-sm text-gray-700">{spectrumEnd}</span>
        </div>
      </div>
    </div>
  );
}

type RoutingPenaltySettingsCreateRoutingJobSlideProps = {
  earlyPenaltyLevel: number;
  setEarlyPenaltyLevel: (value: number) => void;
  latePenaltyLevel: number;
  setLatePenaltyLevel: (value: number) => void;
};

function RoutingPenaltySettingsCreateRoutingJobSlide({
  earlyPenaltyLevel,
  setEarlyPenaltyLevel,
  latePenaltyLevel,
  setLatePenaltyLevel,
}: RoutingPenaltySettingsCreateRoutingJobSlideProps) {
  return (
    <div className="flex flex-col gap-6 p-4 min-w-[600px]">
      <p className="text-xs text-gray-500">
        Adjust the slider from 0 (maximum distance saving) to 10 (maximum
        on-time priority) to set your preference.
      </p>
      <PenaltySettingSpectrum
        value={earlyPenaltyLevel}
        onChange={setEarlyPenaltyLevel}
        label="Earliness Priority Level"
        spectrumStart="Optimize Distance"
        spectrumEnd="Reduce Early Arrivals"
      />
      <PenaltySettingSpectrum
        value={latePenaltyLevel}
        onChange={setLatePenaltyLevel}
        label="Lateness Priority Level"
        spectrumStart="Optimize Distance"
        spectrumEnd="Reduce Lateness"
      />
    </div>
  );
}

export default RoutingPenaltySettingsCreateRoutingJobSlide;
