import React, { useEffect, ReactNode, useMemo, useContext } from "react";
import { Badge } from "@src/common/components";
import { Loading } from "@common/components/Loading";
import { useCourieStore } from "@src/common/lib/store";
import { formatDateWithDay, isToday } from "@src/common/lib/DateUtils";
import {
  ArrowTopRightOnSquareIcon,
  BeakerIcon,
  ExclamationCircleIcon,
} from "@heroicons/react/24/outline";
import Image from "next/image";
import {
  DispatchPreview,
  DispatchPreviewSaveInput,
  Driver,
  DriverWorkType,
  GetDispatchPreviewsQueryVariables,
  ShipmentStatus,
  TaskStatus,
} from "@api/graphql/generated/generated-types";
import AssignedDriverView from "../AssignedDriverView";
import { ExpandableDriverCard } from "@src/shipments/components/ExpandableDriverCard";
import {
  driversToDispatchCandidateViews,
  makeDispatchCandidateViews,
} from "@src/shipments/utils/makeDispatchCandidateViews";
import {
  DispatchCandidateSortingType,
  ShipmentRow,
} from "@src/shipments/types";
import DispatchCandidateSortDropdown from "@src/shipments/components/DispatchCandidateSortDropdown";
import { toDate } from "@src/common/lib/DateUtils";
import { MAX_WIDTH_TO_HANDLE_IN_BETWEEN_NEGATIVE_SPACE_IN_LARGE_SCREEN } from "@src/shipments/constants/styleConstants";
import classNames from "classnames";
import { DRIVER_API_LIMIT } from "@src/common/constants/apiConstants";
import moment from "moment";
import { AuthContext } from "@src/auth/components/AuthProvider";
import {
  CandidatesProvider,
  useCandidates,
} from "./contexts/CandidatesProvider";
import {
  CandidatesGraphQLProvider,
  useCandidatesGraphQL,
} from "./contexts/CandidatesGraphQLProvider";
import CourieButton from "../../Button/Button";
import { useRouter } from "next/router";

export type DispatchCandidateView = {
  id: number | string;
  title: string;
  fullName: string;
  subText: ReactNode;
  image: string;
  badgeText?: string;
  distanceInMeters?: number;
  distanceAdditionalInMeters?: number;
  vehicleType?: string;
  remainingWorkloadInSeconds?: number;
  phone?: string;
  isActive: boolean;
  driver: Driver;
  dispatchPreview: DispatchPreview | undefined;
  workType: DriverWorkType;
  __typename: string;
};

enum DispatchPreviewSection {
  ONLINE = "ONLINE",
  OFFLINE = "OFFLINE",
  SPECIALIZED = "SPECIALIZED",
}

export type DispatchCandidatesWidgetProps = {
  selectedRow: ShipmentRow | undefined;
  onClickUnassign?: () => void;
  onClickChangeRouting?: () => void;
  onClickTransferDriver?: () => void;
  isAssignDriverModalOpen: boolean;
  setIsAssignDriverModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  selectedDriver: Driver | undefined;
  setSelectedDriver: React.Dispatch<React.SetStateAction<Driver | undefined>>;
  setDispatchPreviewSortingType: React.Dispatch<
    React.SetStateAction<string | undefined>
  >;
  setDispatchPreviews: React.Dispatch<
    React.SetStateAction<
      DispatchPreviewSaveInput | DispatchPreviewSaveInput[] | undefined
    >
  >;
  setDriverRank: React.Dispatch<React.SetStateAction<number | undefined>>;
  setDispatchPreviewSection: React.Dispatch<
    React.SetStateAction<string | null>
  >;
};

const taskStatusFilterForDispatchPreviews = [
  TaskStatus.Created,
  TaskStatus.Started,
];

function DispatchCandidatesWidget({
  selectedRow,
  onClickUnassign,
  onClickChangeRouting,
  onClickTransferDriver,
  setIsAssignDriverModalOpen,
  setSelectedDriver,
  setDispatchPreviewSortingType,
  setDispatchPreviews,
  setDriverRank,
  setDispatchPreviewSection,
}: DispatchCandidatesWidgetProps) {
  // contexts
  const { courierId } = useContext(AuthContext);
  const { setSelectedWidgetItem } = useCourieStore();
  const router = useRouter();
  const {
    candidates,
    setCandidates,
    unavailableCandidates,
    setUnavailableCandidates,
    specializedCandidates,
    setSpecializedCandidates,
    dispatchCandidatesSortingType,
    setDispatchCandidatesSortingType,
  } = useCandidates();
  const {
    FetchDriverTasksByDateAndStatus,
    FetchDriverTasksByDateAndStatusData,
    FetchDriverTasksByDateAndStatusLoading,
    getDispatchPreviews,
    dispatchPreviewApiData,
    dispatchPreviewLoading,
    getDispatchPreviewsOffline,
    dispatchPreviewApiDataOffline,
    dispatchPreviewLoadingOffline,
    getDispatchPreviewsSpecialized,
    dispatchPreviewApiDataSpecialized,
    dispatchPreviewLoadingSpecialized,
  } = useCandidatesGraphQL();

  useEffect(() => {
    setDispatchPreviewSortingType(dispatchCandidatesSortingType);
  }, [dispatchCandidatesSortingType]);

  const selectedShipmentsMemo = useMemo(
    () => selectedRow?.shipment,
    [selectedRow?.shipment?.order.displayId, selectedRow?.shipment?.driver]
  );
  const selectedShipmentDate =
    toDate(selectedShipmentsMemo?.shipmentDate) || undefined;
  const canUnassignDriver = selectedRow?.canUnassignDriver;
  const canChangeRouting = selectedRow?.canChangeRouting;
  const canTransferDriver =
    selectedRow?.shipment?.status !== ShipmentStatus.Completed;
  let showWarningBadge = false;

  if (
    selectedShipmentsMemo &&
    !isToday(selectedShipmentsMemo.firstEffectiveStopDate)
  ) {
    showWarningBadge = true;
  }

  useEffect(() => {
    if (
      dispatchCandidatesSortingType ===
      DispatchCandidateSortingType.ALPHABETICAL
    ) {
      setDispatchCandidatesSortingType(
        DispatchCandidateSortingType.LEAST_DEVIATION
      );
      return;
    }

    fetchDispatchPreviews();
  }, [selectedShipmentsMemo]);

  useEffect(() => {
    if (
      dispatchCandidatesSortingType ===
        DispatchCandidateSortingType.ALPHABETICAL &&
      courierId &&
      selectedShipmentDate &&
      selectedShipmentsMemo
    ) {
      FetchDriverTasksByDateAndStatus({
        variables: {
          courierId: courierId,
          date: selectedShipmentsMemo.firstEffectiveStopDate,
          limit: DRIVER_API_LIMIT,
          statusFilter: [TaskStatus.Created, TaskStatus.Started],
        },
      });
    } else {
      fetchDispatchPreviews();
    }
  }, [dispatchCandidatesSortingType, selectedShipmentsMemo]);

  useEffect(() => {
    if (!selectedShipmentsMemo || !dispatchPreviewApiData) {
      return;
    }
    if (
      dispatchCandidatesSortingType !==
      DispatchCandidateSortingType.ALPHABETICAL
    ) {
      setCandidates(
        makeDispatchCandidateViews(
          dispatchPreviewApiData,
          dispatchCandidatesSortingType
        )
      );
      getDispatchPreviewsOffline({
        variables: {
          isActive: false,
          workType: DriverWorkType.Regular,
          shipmentId: selectedShipmentsMemo.id,
          taskStatusFilter: taskStatusFilterForDispatchPreviews,
          shipmentDate: selectedShipmentsMemo.firstEffectiveStopDate,
        } as GetDispatchPreviewsQueryVariables,
        fetchPolicy: "cache-and-network",
      });
    }
  }, [dispatchPreviewApiData]);

  useEffect(() => {
    if (
      dispatchCandidatesSortingType !==
        DispatchCandidateSortingType.ALPHABETICAL &&
      dispatchPreviewApiDataOffline
    ) {
      const offlineCandidates = makeDispatchCandidateViews(
        dispatchPreviewApiDataOffline,
        dispatchCandidatesSortingType
      );

      setUnavailableCandidates(offlineCandidates);
      if (selectedShipmentsMemo) {
        getDispatchPreviewsSpecialized({
          variables: {
            workType: DriverWorkType.Specialized,
            shipmentId: selectedShipmentsMemo.id,
            taskStatusFilter: taskStatusFilterForDispatchPreviews,
            shipmentDate: selectedShipmentsMemo.shipmentDate,
          } as GetDispatchPreviewsQueryVariables,
          fetchPolicy: "cache-and-network",
        });
      }
    }
  }, [dispatchCandidatesSortingType, dispatchPreviewApiDataOffline]);

  useEffect(() => {
    if (
      dispatchCandidatesSortingType !==
        DispatchCandidateSortingType.ALPHABETICAL &&
      dispatchPreviewApiDataSpecialized
    ) {
      setSpecializedCandidates(
        makeDispatchCandidateViews(
          dispatchPreviewApiDataSpecialized,
          dispatchCandidatesSortingType
        )
      );
    }
  }, [dispatchCandidatesSortingType, dispatchPreviewApiDataSpecialized]);

  useEffect(() => {
    if (
      !FetchDriverTasksByDateAndStatusData ||
      dispatchCandidatesSortingType !==
        DispatchCandidateSortingType.ALPHABETICAL
    ) {
      return;
    }
    const drivers = FetchDriverTasksByDateAndStatusData?.drivers?.edges?.map(
      (edge) => edge?.node
    );
    if (drivers) {
      const onlineRegularDrivers = drivers
        .filter(
          (driver) =>
            driver?.workType === DriverWorkType.Regular && driver?.isActive
        )
        .sort((a, b) => {
          if (a?.firstName && b?.firstName) {
            return a.firstName.localeCompare(b.firstName);
          }
          return 0;
        });
      const offlineRegularDrivers = drivers
        .filter(
          (driver) =>
            driver?.workType === DriverWorkType.Regular && !driver?.isActive
        )
        .sort((a, b) => {
          if (a?.firstName && b?.firstName) {
            return a.firstName.localeCompare(b.firstName);
          }
          return 0;
        });
      const specializedDrivers = drivers
        .filter((driver) => driver?.workType === DriverWorkType.Specialized)
        .sort((a, b) => {
          if (a?.firstName && b?.firstName) {
            return a.firstName.localeCompare(b.firstName);
          }
          return 0;
        });
      setCandidates(
        driversToDispatchCandidateViews(onlineRegularDrivers as Driver[])
      );
      setUnavailableCandidates(
        driversToDispatchCandidateViews(offlineRegularDrivers as Driver[])
      );
      setSpecializedCandidates(
        driversToDispatchCandidateViews(specializedDrivers as Driver[])
      );
      return;
    }
  }, [dispatchCandidatesSortingType, FetchDriverTasksByDateAndStatusData]);

  const availableCandidates = candidates?.filter(
    (candidate) =>
      candidate.isActive && candidate.workType === DriverWorkType.Regular
  );

  const fetchDispatchPreviews = async () => {
    if (
      selectedShipmentsMemo &&
      selectedShipmentsMemo.status === ShipmentStatus.Created
    ) {
      try {
        getDispatchPreviews({
          variables: {
            isActive: true,
            workType: DriverWorkType.Regular,
            shipmentId: selectedShipmentsMemo.id,
            taskStatusFilter: taskStatusFilterForDispatchPreviews,
            shipmentDate: selectedShipmentsMemo.firstEffectiveStopDate,
          } as GetDispatchPreviewsQueryVariables,
          fetchPolicy: "cache-and-network",
        });
      } catch (error) {
        console.error("Error fetching dispatch previews:", error);
      }
    }
  };

  const keyBoardSelectedDriverId = 0;

  function findDriverRankingAndSet(selectedDriver: Driver) {
    if (!selectedShipmentsMemo) {
      return;
    }

    const allCandidates = [
      ...candidates,
      ...unavailableCandidates,
      ...specializedCandidates,
    ];

    const selectedCandidateIndex = allCandidates.findIndex(
      (candidate) => candidate.driver.id === selectedDriver.id
    );

    const selectedCandidate = allCandidates[selectedCandidateIndex];

    const onlineIndex = candidates.findIndex(
      (candidate) => candidate.driver.id === selectedDriver.id
    );
    const offlineIndex = unavailableCandidates.findIndex(
      (candidate) => candidate.driver.id === selectedDriver.id
    );
    const specializedIndex = specializedCandidates.findIndex(
      (candidate) => candidate.driver.id === selectedDriver.id
    );

    let dispatchPreviewSection = "";
    let driverRank = 0;

    if (onlineIndex !== -1) {
      dispatchPreviewSection = DispatchPreviewSection.ONLINE;
      driverRank = onlineIndex + 1;
    } else if (offlineIndex !== -1) {
      dispatchPreviewSection = DispatchPreviewSection.OFFLINE;
      driverRank = offlineIndex + 1;
    } else if (specializedIndex !== -1) {
      dispatchPreviewSection = DispatchPreviewSection.SPECIALIZED;
      driverRank = specializedIndex + 1;
    }

    setDriverRank(driverRank);
    setDispatchPreviewSection(dispatchPreviewSection || null);

    const dispatchPreviews: DispatchPreviewSaveInput[] = allCandidates.map(
      (candidate) => {
        let section = "";

        if (candidate.workType !== DriverWorkType.Regular) {
          section = DispatchPreviewSection.SPECIALIZED;
        } else if (candidate.driver.isActive) {
          section = DispatchPreviewSection.ONLINE;
        } else {
          section = DispatchPreviewSection.OFFLINE;
        }

        return {
          distanceAdditionalInMeters: candidate.distanceAdditionalInMeters,
          distanceInMeters: candidate.dispatchPreview?.distanceInMeters,
          driverId: candidate.driver.id,
          durationAdditionalInSeconds:
            candidate.dispatchPreview?.durationAdditionalInSeconds,
          durationInSeconds: candidate.dispatchPreview?.durationInSeconds,
          numCurrentTasks: candidate.driver.tasks?.length || 0,
          remainingWorkloadInSeconds: candidate.remainingWorkloadInSeconds,
          section,
          shipmentId: selectedShipmentsMemo.id,
          tags: candidate.dispatchPreview?.tags || [],
        };
      }
    );

    setDispatchPreviews(dispatchPreviews);
  }

  const getLoadingState = (loading: boolean) => {
    if (
      dispatchCandidatesSortingType ===
      DispatchCandidateSortingType.ALPHABETICAL
    ) {
      return FetchDriverTasksByDateAndStatusLoading;
    } else {
      return loading;
    }
  };

  if (
    selectedShipmentsMemo?.status === ShipmentStatus.Created &&
    selectedShipmentsMemo?.routingJobId
  ) {
    return (
      <div className="h-full bg-white border rounded-t-3xl ">
        <span className="text-3xl justify-center flex pt-20">
          <BeakerIcon className="w-8 h-8 animate-bounce" />
        </span>
        <div className="flex items-center justify-center pb-0">
          Order {selectedShipmentsMemo?.order?.displayId}
        </div>
        <div className="flex items-center justify-center p-2 pt-0 text-slate-600 text-sm">
          is currently being optimized for routing
        </div>
        <div className="flex items-center justify-center p-2 pt-0 text-slate-600 text-sm">
          <CourieButton
            size={"xs"}
            color={"gray"}
            onClick={() => {
              selectedShipmentsMemo &&
                router.push(
                  `/routingjob/${selectedShipmentsMemo.routingJobId}`
                );
            }}
          >
            <ArrowTopRightOnSquareIcon className="w-4 h-4 mr-1 inline-block" />
            View Status
          </CourieButton>
        </div>
      </div>
    );
  }

  if (
    selectedShipmentsMemo?.status === ShipmentStatus.Completed &&
    !selectedShipmentsMemo.driver
  ) {
    return (
      <div className="h-full bg-white">
        <span className="text-3xl justify-center flex pt-20">🐼</span>
        <div className="flex items-center justify-center pb-0">
          Order {selectedShipmentsMemo?.order?.displayId}
        </div>
        <div className="flex items-center justify-center p-2 pt-0 text-slate-600 text-sm">
          has been completed without a driver
        </div>
      </div>
    );
  }

  if (selectedShipmentsMemo && selectedShipmentsMemo.driver) {
    return (
      <div className="border rounded-3xl px-4 pt-4 pb-4 bg-white dark:bg-gray-800">
        <AssignedDriverView
          driver={selectedShipmentsMemo.driver}
          onClickUnassign={onClickUnassign}
          onClickChangeRouting={onClickChangeRouting}
          onClickTransferDriver={onClickTransferDriver}
          canUnassignDriver={canUnassignDriver || false}
          canChangeRouting={canChangeRouting || false}
          canTransferDriver={canTransferDriver || false}
        />
      </div>
    );
  }
  return (
    <>
      <div className="bg-white dark:bg-gray-800 border rounded-t-3xl h-full overflow-auto">
        <DispatchCandidateSortDropdown
          sortingType={dispatchCandidatesSortingType}
          setSortingType={setDispatchCandidatesSortingType}
        />
        <Loading
          loading={getLoadingState(dispatchPreviewLoading)}
          text={"Calculating the best routes..."}
          image={
            <Image
              src={"/route-calculator.png"}
              alt="QB"
              height={280}
              width={280}
              className="cursor-pointer hover:opacity-80 transition-opacity"
            />
          }
          className="h-full w-full"
        >
          {showWarningBadge && (
            <div className="flex items-center justify-center p-2">
              <Badge
                color="warning"
                icon={
                  ExclamationCircleIcon as React.FC<
                    React.SVGProps<SVGSVGElement>
                  >
                }
                size={"sm"}
              >
                Assigning order for{" "}
                {selectedShipmentsMemo &&
                  formatDateWithDay(
                    selectedShipmentsMemo.firstEffectiveStopDate
                  )}
              </Badge>
            </div>
          )}
          {availableCandidates &&
            availableCandidates.map((item, i) => (
              <div key={i}>
                <ExpandableDriverCard
                  showBorder={true}
                  title={`${item.driver.firstName} ${item.driver.lastName}`}
                  fullName={item.fullName || ""}
                  subText={item.subText}
                  badgeText={item.badgeText}
                  image={item.image}
                  onClick={() => {
                    setSelectedWidgetItem(item);
                    setIsAssignDriverModalOpen(true);
                    setSelectedDriver(item.driver);
                    findDriverRankingAndSet(item.driver);
                  }}
                  expanded={false}
                  onExpand={() => {}}
                  onCollapse={() => {}}
                  isKeyboardSelected={keyBoardSelectedDriverId === item.id}
                  driver={item.driver}
                />
              </div>
            ))}

          <Loading
            loading={getLoadingState(
              dispatchPreviewLoadingOffline && !dispatchPreviewLoading
            )}
          >
            {unavailableCandidates && unavailableCandidates.length > 0 && (
              <div
                className="p-2 pl-4 pt-0 text-xs font-light mx-auto"
                style={{
                  maxWidth:
                    MAX_WIDTH_TO_HANDLE_IN_BETWEEN_NEGATIVE_SPACE_IN_LARGE_SCREEN,
                }}
              >
                Offline drivers ({unavailableCandidates.length})
              </div>
            )}

            {unavailableCandidates &&
              unavailableCandidates.map((item, i) => (
                <div key={i} className="opacity-50">
                  <ExpandableDriverCard
                    title={`${item.driver.firstName} ${item.driver.lastName}`}
                    fullName={item.fullName || ""}
                    subText={item.subText}
                    badgeText={item.badgeText}
                    image={item.image}
                    onClick={() => {
                      setSelectedWidgetItem(item);
                      setIsAssignDriverModalOpen(true);
                      setSelectedDriver(item.driver);
                      findDriverRankingAndSet(item.driver);
                    }}
                    expanded={false}
                    onExpand={() => {}}
                    onCollapse={() => {}}
                    driver={item.driver}
                  />
                </div>
              ))}
          </Loading>

          <Loading
            loading={getLoadingState(
              dispatchPreviewLoadingSpecialized && !dispatchPreviewLoading
            )}
          >
            {!dispatchPreviewLoadingOffline &&
              specializedCandidates &&
              specializedCandidates.length > 0 && (
                <div
                  className="p-2 pl-4 pt-0 text-xs font-light mx-auto"
                  style={{
                    maxWidth:
                      MAX_WIDTH_TO_HANDLE_IN_BETWEEN_NEGATIVE_SPACE_IN_LARGE_SCREEN,
                  }}
                >
                  Specialized drivers ({specializedCandidates.length})
                </div>
              )}

            {!dispatchPreviewLoadingOffline &&
              specializedCandidates &&
              specializedCandidates.length > 0 && (
                <div>
                  {specializedCandidates.map((item, i) => (
                    <div
                      key={i}
                      className={classNames({
                        "opacity-50": !item.isActive,
                      })}
                    >
                      <ExpandableDriverCard
                        title={`${item.driver.firstName} ${item.driver.lastName}`}
                        fullName={item.fullName || ""}
                        subText={item.subText}
                        badgeText={item.badgeText}
                        image={item.image}
                        onClick={() => {
                          setSelectedWidgetItem(item);
                          setIsAssignDriverModalOpen(true);
                          setSelectedDriver(item.driver);
                          findDriverRankingAndSet(item.driver);
                        }}
                        expanded={false}
                        onExpand={() => {}}
                        onCollapse={() => {}}
                        driver={item.driver}
                      />
                    </div>
                  ))}
                </div>
              )}
          </Loading>
        </Loading>
      </div>
    </>
  );
}

const DispatchCandidatesWidgetWrapper: React.FC<DispatchCandidatesWidgetProps> =
  (props) => {
    return (
      <CandidatesProvider>
        <CandidatesGraphQLProvider>
          <DispatchCandidatesWidget {...props} />
        </CandidatesGraphQLProvider>
      </CandidatesProvider>
    );
  };

export default DispatchCandidatesWidgetWrapper;
