import { Stop, Task } from "@api/graphql/generated/generated-types";
import React, { createContext, useContext, ReactNode, useState } from "react";

interface DriverRouteViewSelectionContextType {
  hoveredTask: Task | Stop | undefined;
  setHoveredTask: React.Dispatch<React.SetStateAction<Task | Stop | undefined>>;
  hoverTaskLabel: number | undefined;
  setHoverTaskLabel: React.Dispatch<React.SetStateAction<number | undefined>>;
}

const DriverRouteViewSelectionContext =
  createContext<DriverRouteViewSelectionContextType | undefined>(undefined);

interface DriverRouteViewSelectionProviderProps {
  children: ReactNode;
}

export const DriverRouteViewSelectionProvider: React.FC<DriverRouteViewSelectionProviderProps> =
  ({ children }) => {
    const [hoveredTask, setHoveredTask] =
      useState<Task | Stop | undefined>(undefined);
    const [hoverTaskLabel, setHoverTaskLabel] =
      useState<number | undefined>(undefined);

    // Add more states and functions as needed

    return (
      <DriverRouteViewSelectionContext.Provider
        value={{
          hoveredTask,
          setHoveredTask,
          hoverTaskLabel,
          setHoverTaskLabel,
        }}
      >
        {children}
      </DriverRouteViewSelectionContext.Provider>
    );
  };

export const useDriverRouteViewSelectionProvider = () => {
  const context = useContext(DriverRouteViewSelectionContext);
  if (context === undefined) {
    throw new Error(
      "useDriverRouteViewSelectionProvider must be used within a DriverRouteViewSelectionProvider"
    );
  }
  return context;
};
