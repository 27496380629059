import {
  Order,
  OrderAcquisitionType,
  OrderPrice,
  Shipment,
  ShipmentStopType,
  Stop,
  TimeWindow,
} from "@api/graphql/generated/generated-types";
import {
  OrderDetailsWidgetDataProps,
  GeneralPanelProps,
  StopPanelProps,
  StopPanelPropsBodyItem,
  PricingPanelProps,
} from "@src/common/components/Widgets/OrderDetailsWidget";
import { displayShipmentStatus } from "@src/shipments/utils/shipmentStatusDisplayer";
import { formatStopAddress } from "./stopFullAddressFormatter";
import {
  formatDateTimeFromTimestampScalar,
  formatTime,
} from "@src/common/lib/DateUtils";
import { formatPhoneNumber } from "@src/common/lib/textUtils";
import { InternalNotePanelProps } from "@src/common/components/Widgets/InternalNote";
import { getCompletedAtText } from "./shipmentRowFormatter";

export function makeOrderDetailsWidgetPropsFromShipment(
  shipment: Shipment | undefined,
  orderPrice?: OrderPrice | undefined
): OrderDetailsWidgetDataProps[] {
  if (!shipment) {
    return [];
  }

  const generalPanelProps: GeneralPanelProps[] =
    makeGeneralPanelProps(shipment);
  const stopPanelProps: StopPanelProps[] = makeStopPanelProps(shipment);
  const internalNotePanelProps: InternalNotePanelProps =
    makeInternalNotePanelProps(shipment);

  const panels: OrderDetailsWidgetDataProps[] = [
    {
      panelName: "internalNote",
      internalNotePanelProps: internalNotePanelProps,
    },
    {
      panelName: "Stops",
      stopPanelProps: stopPanelProps,
      generalPanelProps: [],
    },
    {
      panelName: "General",
      generalPanelProps: generalPanelProps,
      stopPanelProps: [],
    },
  ];

  if (shipment.packageItems) {
    panels.push({
      panelName: "Package Items",
      packageItems: shipment.packageItems,
    });
  }

  if (orderPrice) {
    const pricingPanelProps: PricingPanelProps = {
      title: Number(orderPrice.total),
      value: "Order Total",
      breakdown: orderPrice.pricingBreakDown,
      pricingList: undefined,
      showAnimation: false,
    };

    // Insert the pricing panel as the third element of the array
    panels.splice(2, 0, {
      panelName: "Pricing",
      pricingPanelProps: pricingPanelProps,
      generalPanelProps: [],
      stopPanelProps: [],
    });
  }

  if (shipment.packageDescription) {
    panels.push({
      panelName: "Package Description",
      packageDescription: shipment.packageDescription,
    });
  }

  return panels;
}

function makeGeneralPanelProps(shipment: Shipment): GeneralPanelProps[] {
  let result = [
    {
      title: "Tracking",
      value: shipment.trackingNumber || "N/A",
    },
    {
      title: "Customer",
      value: shipment.order.endCustomer.companyName || "N/A",
    },
    {
      title: "Status",
      value: displayShipmentStatus(shipment),
    },

    {
      title: "Created",
      value: formatDateTimeFromTimestampScalar(shipment.createdAt),
    },
    {
      title: "Dispatched",
      value: shipment.assignedAt
        ? formatDateTimeFromTimestampScalar(shipment.assignedAt)
        : "N/A",
    },
    {
      title: "Created By",
      value: shipment.order.acquisitionType
        ? getAcquisitionTypeLabel(
            shipment.order.acquisitionType,
            shipment.order
          )
        : "N/A",
    },
  ];
  const customFieldValues = shipment.order.customFieldValues;
  if (customFieldValues) {
    const customFieldProps = customFieldValues.map((customField) => {
      return {
        title: customField.field.name,
        value: customField.valueDisplay || "N/A",
      };
    });
    result = [...result, ...customFieldProps];
  }
  return result;
}

function getAcquisitionTypeLabel(
  acquisitionType: OrderAcquisitionType,
  order: Order
): string {
  switch (acquisitionType) {
    case OrderAcquisitionType.CustomerPortal:
      if (order.createdByEndUser) {
        return order.createdByEndUser.email;
      }
      if (order.createdByEndCustomer?.companyName) {
        return order.createdByEndCustomer.companyName;
      }
      return "Customer Portal";
    case OrderAcquisitionType.User:
      if (order.createdByUser?.email) {
        return order.createdByUser.email;
      }
      return "Dispatcher";
    default:
      return "N/A";
  }
}

function makeStopPanelProps(shipment: Shipment): StopPanelProps[] {
  const getFirstTaskSequence = (stop: Stop) => {
    return stop.tasks?.[0]?.sequenceNumber ?? Number.MAX_SAFE_INTEGER;
  };

  return [
    ...shipment.stops
      .filter((stop) => stop.type === ShipmentStopType.PickUp)
      .sort((a, b) => getFirstTaskSequence(a) - getFirstTaskSequence(b))
      .map((stop) => makeStopPanelPropsFromStop(stop)),
    ...shipment.stops
      .filter((stop) => stop.type === ShipmentStopType.DropOff)
      .sort((a, b) => getFirstTaskSequence(a) - getFirstTaskSequence(b))
      .map((stop) => makeStopPanelPropsFromStop(stop)),
    ...shipment.stops
      .filter((stop) => stop.type === ShipmentStopType.Return)
      .sort((a, b) => getFirstTaskSequence(a) - getFirstTaskSequence(b))
      .map((stop) => makeStopPanelPropsFromStop(stop)),
  ];
}

function makeInternalNotePanelProps(shipment: Shipment): any {
  return {
    shipmentId: shipment.id,
    textareaValue: shipment.internalNote || "",
  };
}

export function makeStopPanelPropsFromStop(stop: Stop): StopPanelProps {
  const bodyItems: StopPanelPropsBodyItem[] = [];
  if (stop.stopDate) {
    bodyItems.push({
      title: "Stop Date",
      value: stop.stopDate,
    });
  }
  if (stop.companyName) {
    bodyItems.push({
      title: "Company",
      value: stop.companyName,
    });
  }
  if (stop.contactName) {
    bodyItems.push({
      title: "Contact Name",
      value: stop.contactName,
    });
  }
  if (stop.phone) {
    bodyItems.push({
      title: "Phone",
      value: formatPhoneNumber(stop.phone),
    });
  }
  bodyItems.push({
    title: "Address",
    value: formatStopAddress(stop),
  });
  if (stop.suite) {
    bodyItems.push({
      title: "Suite",
      value: stop.suite,
    });
  }
  if (stop.instruction) {
    bodyItems.push({
      title: "Instruction",
      value: stop.instruction,
      className: "text-blue-500",
    });
  }
  if (stop.driverNote) {
    bodyItems.push({
      title: "Driver Note",
      value: stop.driverNote,
      className: "text-yellow-300",
    });
  }

  const eta: string | undefined = formatEta(stop);
  const arrivedAt: string | undefined = formatArrivedAt(stop);
  const completedAt = getCompletedAtText(stop);

  if (eta && !arrivedAt) {
    bodyItems.push({
      title: "ETA",
      value: eta,
    });
  }
  if (arrivedAt) {
    bodyItems.push({
      title: "Arrived",
      value: arrivedAt,
    });
  }
  if (completedAt) {
    bodyItems.push({
      title: "Completed",
      value: completedAt,
      className: "text-green-500",
    });
  }

  return {
    title: stop.type,
    subtext: formatTimeWindow(stop.timeWindow),
    bodyItems: bodyItems,
    stop: stop,
  };
}

function formatEta(stop: Stop): string | undefined {
  if (!stop.tasks || stop.tasks.length === 0) {
    return undefined;
  }

  const taskWithEta = stop.tasks.find(
    (task) =>
      task.etaEpochSeconds !== null && task.etaEpochSeconds !== undefined
  );
  if (!taskWithEta) {
    return undefined;
  }

  const eta: Date = new Date(parseInt(stop.tasks[0].etaEpochSeconds) * 1000);
  return formatTime(eta);
}

function formatArrivedAt(stop: Stop): string | undefined {
  if (!stop.tasks || stop.tasks.length === 0) {
    return undefined;
  }
  if (!stop.tasks[0].arrivedAt) {
    return undefined;
  }
  const taskWithArrivedAt = stop.tasks.find(
    (task) => task.arrivedAt !== null && task.arrivedAt !== undefined
  );

  if (!taskWithArrivedAt) {
    return undefined;
  }

  const arrivedAt: Date = new Date(
    parseInt(taskWithArrivedAt.arrivedAt) * 1000
  );
  return formatTime(arrivedAt);
}

function formatTimeWindow(timeWindow: TimeWindow) {
  const windowOpen: Date | undefined = timeWindow.open
    ? new Date(parseInt(timeWindow.open) * 1000)
    : undefined;
  const windowClose: Date | undefined = timeWindow.close
    ? new Date(parseInt(timeWindow.close) * 1000)
    : undefined;

  if (!windowOpen && !windowClose) {
    return "No time window specified";
  }

  if (!windowOpen) {
    return `Deliver by ${formatTime(windowClose)}`;
  }
  if (!windowClose) {
    return `Ready ${formatTime(windowOpen)}`;
  }

  return `Between ${formatTime(windowOpen)} - ${formatTime(windowClose)}`;
}
