import React, { ComponentProps, FC } from "react";
import { DriverAvatar } from "@src/common/components/Avatar";
import { DriverMapItem } from "@src/common/components/Widgets/DriverMapWidget";
import classNames from "classnames";
import { ShipmentRow } from "../types";

interface ShipmentStatusCellProps {
  status?: string;
  StatusIcon?: FC<ComponentProps<"svg">>;
  driverPhoto?: string;
  driverName?: string;
  driver?: DriverMapItem;
  isCompleted?: boolean;
  row?: ShipmentRow;
}

export function ShipmentStatusCell({
  status,
  StatusIcon,
  driverPhoto,
  driverName,
  driver,
  isCompleted,
  row,
}: ShipmentStatusCellProps) {
  const iconClassNames = classNames("h-5 w-5", {
    "text-green-400": isCompleted,
  });

  const renderDriverOrStatusIcon = () => {
    if (status !== "Unassigned" && !row?.shipment?.routingJobId) {
      return (
        <DriverAvatar
          driverPhoto={driverPhoto}
          driverName={driverName}
          size={28}
          isActive={driver?.isActive}
        />
      );
    }

    if (StatusIcon) {
      return <StatusIcon className={iconClassNames} />;
    }

    return null;
  };

  return (
    <div className="flex gap-2 items-center">
      <div className="flex flex-init flex-col gap-2" style={{ width: 110 }}>
        <div className="flex flex-init font-bold text-xs">
          <div className="flex items-center gap-1">
            {renderDriverOrStatusIcon()}
            <div className="flex flex-col">
              <div
                className={classNames({
                  "flex-1 ml-1": true,
                  "text-orange-400": status === "Unconfirmed",
                })}
              >
                {status}
              </div>
              {driverName && (
                <div
                  className="flex flex-1 text-xs ml-1 font-light truncate"
                  style={{ maxWidth: 60 }}
                >
                  {driverName}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
