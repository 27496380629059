import React, { Fragment, useContext, useEffect, useState } from "react";
import { Listbox, Transition } from "@headlessui/react";
import { ChevronUpDownIcon } from "@heroicons/react/20/solid";
import { DispatchCandidateSortingType } from "../types";
import { MAX_WIDTH_TO_HANDLE_IN_BETWEEN_NEGATIVE_SPACE_IN_LARGE_SCREEN } from "../constants/styleConstants";
import { AuthContext } from "@src/auth/components/AuthProvider";

type Props = {
  sortingType: DispatchCandidateSortingType;
  setSortingType: (type: DispatchCandidateSortingType) => void;
};

export const DispatchCandidateSortingTypeExtended = {
  ...DispatchCandidateSortingType,
  ALPHABETICAL: "Alphabetical" as const,
};
function DispatchCandidateSortHeader({ sortingType, setSortingType }: Props) {
  const { courierId } = useContext(AuthContext);
  const [selected, setSelected] = useState(sortingType);

  useEffect(() => {
    setSelected(sortingType);
  }, [sortingType]);

  const handleSelection = (type: DispatchCandidateSortingType) => {
    setSelected(type);
    setSortingType(type);
  };

  const getFilteredSortingTypes = (showAlphabeticalSort: boolean) => {
    const { ALPHABETICAL, ...rest } = DispatchCandidateSortingTypeExtended;
    return showAlphabeticalSort ? DispatchCandidateSortingTypeExtended : rest;
  };

  const showAlphabeticalSort: boolean =
    courierId !== null &&
    [
      "COURcc5dbe3b003142e4", // Awesome Courier (Local)
      "COURcc5dbe3b00310001", // Aarish Express (Dev)
      "COUR00000000004", // Accel (Prod)
    ].includes(courierId);

  return (
    <div
      className="flex flex-row items-center mt-4 mb-4 mx-auto"
      style={{
        maxWidth: MAX_WIDTH_TO_HANDLE_IN_BETWEEN_NEGATIVE_SPACE_IN_LARGE_SCREEN,
      }}
    >
      <div className="flex-1 flex justify-beginning items-center gap-2 pl-4 pr-3">
        <div className="flex-initial text-xs text-slate-500"> Sort by </div>
        <div className="flex-initial w-40">
          <Listbox value={selected} onChange={handleSelection}>
            <div className="relative mt-1">
              <Listbox.Button className="relative w-full cursor-default rounded-lg bg-white py-2 pl-3 pr-10 text-left shadow-md border">
                <span className="block truncate">{selected}</span>
                <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                  <ChevronUpDownIcon
                    className="h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                </span>
              </Listbox.Button>
              <Transition
                as={Fragment}
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Listbox.Options
                  style={{ zIndex: 200 }}
                  className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg"
                >
                  {Object.values(getFilteredSortingTypes(showAlphabeticalSort))
                    .filter((type) => type !== selected)
                    .sort((a, b) => a.localeCompare(b))
                    .map((type, idx) => (
                      <Listbox.Option
                        key={idx}
                        className={({ active }) =>
                          `relative hover:cursor-pointer select-none py-2 pl-4 pr-4 ${
                            active
                              ? "bg-blue-100 text-blue-900"
                              : "text-gray-900"
                          }`
                        }
                        value={type}
                      >
                        {({ selected }) => (
                          <>
                            <span
                              className={`block truncate ${
                                selected ? "font-medium" : "font-normal"
                              }`}
                            >
                              {type}
                            </span>
                          </>
                        )}
                      </Listbox.Option>
                    ))}
                </Listbox.Options>
              </Transition>
            </div>
          </Listbox>
        </div>
      </div>
    </div>
  );
}

export default DispatchCandidateSortHeader;
