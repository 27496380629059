import {
  Driver,
  DriverEdge,
  TaskStatus,
  useFetchDriverTasksByDateAndStatusLazyQuery,
} from "@api/graphql/generated/generated-types";
import { AuthContext } from "@src/auth/components/AuthProvider";
import { DRIVER_API_LIMIT } from "@src/common/constants/apiConstants";
import { sortDriversByName } from "@src/common/lib/driverSorters";
import { makeDriverOptions } from "@src/shipments/utils/makeDriverOptions";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import { DriverOption } from "../DriverFilterSelector";
import { DriverNameCell } from "@src/common/components/DriverNameCell/DriverNameCell";
import { Loading } from "@src/common/components";
import classNames from "classnames";

type Props = {
  setSelectedDriver: React.Dispatch<React.SetStateAction<Driver | undefined>>;
  selectedDriver: Driver | undefined;
  onDriverSelect?: () => void;
  hideDriverId?: string;
};

function DriverSelectorList({
  selectedDriver,
  setSelectedDriver,
  onDriverSelect,
  hideDriverId,
}: Props) {
  const { courierId } = useContext(AuthContext);
  const [drivers, setDrivers] = useState<DriverOption[]>([]);
  const [
    FetchDriverTasksByDateAndStatus,
    {
      loading: FetchDriverTasksByDateAndStatusLoading,
      error: FetchDriverTasksByDateAndStatusError,
    },
  ] = useFetchDriverTasksByDateAndStatusLazyQuery();
  useEffect(() => {
    if (!courierId) {
      return;
    }
    FetchDriverTasksByDateAndStatus({
      variables: {
        courierId,
        date: moment().format("YYYY-MM-DD"),
        statusFilter: [TaskStatus.Created, TaskStatus.Started],
        limit: DRIVER_API_LIMIT,
      },
    }).then((res) => {
      const fetchedDrivers = makeDriverOptions(
        (res.data?.drivers?.edges as DriverEdge[]) || []
      );
      const sortedDrivers = sortDriversByName(fetchedDrivers);
      const filteredDrivers = sortedDrivers.filter(
        (driver) => driver.id !== hideDriverId
      );
      setDrivers(filteredDrivers);
    });
  }, [courierId]);

  return (
    <Loading
      loading={FetchDriverTasksByDateAndStatusLoading}
      text="Loading drivers"
      className=" max-h-96 overflow-auto flex flex-col gap-2"
    >
      {drivers.map((driver) => (
        <div
          key={driver.id}
          className={classNames({
            "p-1 rounded-md hover:bg-slate-100 hover:cursor-pointer": true,
            "bg-primary-100": driver.driver?.id === selectedDriver?.id,
          })}
          onClick={() => {
            setSelectedDriver(driver.driver as Driver | undefined);
            onDriverSelect && onDriverSelect();
          }}
        >
          <DriverNameCell
            name={driver.name}
            avatarUrl={driver.url}
            isAvailable={driver.isActive}
            row={driver}
            driver={driver.driver as Driver | undefined}
          />
        </div>
      ))}
    </Loading>
  );
}

export default DriverSelectorList;
