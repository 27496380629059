import React, { useEffect, useState } from "react";
import {
  BaseModal,
  BaseModalBody,
  BaseModalHeader,
  Checkbox,
  DateBasedPaginator,
  Loading,
} from "@src/common/components";
import classNames from "classnames";
import { ShipmentRow, ShipmentsPrimaryFilterType } from "@src/shipments/types";
import {
  SHIPMENT_TABLE_COLUMNS,
  TableColumnType,
} from "./ShipmentTableColumns";
import { TableContainer } from "@src/common/components";
import SortingOptionsDropdown from "./SortingOptionsDropdown";
import { SORTED_COLUMN_BG } from "@src/common/lib/tableUtils";
import { useHeaderSorting } from "@src/common/hooks/useHeaderSorting";
import { sortFields } from "../constants/sortFields";
import PrimaryFilters from "./PrimaryFilters";
import { DriverFilterSelector } from "./DriverFilterSelector";
import { useShipmentFiltersContext } from "../context/ShipmentFiltersContext";
import { useShipmentsCoreDataContext } from "../context/ShipmentsCoreData";
import TableFilterPopover from "@src/common/components/TableFilterPopover";
import { useTableControl } from "../context/TableControlContext";
import OrdersFilter from "./OrdersFilter";
import OrderRow from "./OrderRow";
import SearchShipmentsInput from "./SearchShipmentsInput";
import { LayersManager } from "baseui/layer";
import ShipmentsTableFooter from "./ShipmentsTableFooter";
import {
  ArrowDownIcon,
  ArrowUpIcon,
  SparklesIcon,
} from "@heroicons/react/24/outline";
import {
  RoutingJobStatus,
  ShipmentsSorting,
  ShipmentStatus,
  SortingDirection,
} from "@api/graphql/generated/generated-types";
import RoutingJobsStatusButtons from "./RoutingJobsStatusButtons";
import { useShipmentSelectionContext } from "../context/ShipmentSelectionContext";
import CourieButton from "@src/common/components/Button/Button";
import { useModalsContext } from "../context/ShipmentsTableModalsContext";
import { OrdersPageModalNames } from "../utils/ordersPageModalNames";
import CheckedShipmentsStatus from "./CheckedShipmentsStatus";

type Props = {};

function ShipmentsTable({}: Props) {
  const {
    filters,
    sortFields: sortFieldsTableControl,
    setSortFields,
  } = useTableControl();
  const { setModalOpen } = useModalsContext();
  const [instructionModalOpen, setInstructionModalOpen] = useState(false);
  const [instructions, setInstructions] = useState<string | undefined>("");
  const [showSelectAllButton, setShowSelectAllButton] = useState(false);
  const { handleSortingClick, areFieldsSorted } = useHeaderSorting();
  const {
    minDate,
    setMinDate,
    maxDate,
    setMaxDate,
    selectedDriverIdFilter,
    setSelectedDriverIdFilter,
    selectedPrimaryFilter,
  } = useShipmentFiltersContext();
  const { rows, routingJobsData } = useShipmentsCoreDataContext();
  const { shipmentsApiResponse, shipmentsApiLoading } =
    useShipmentsCoreDataContext();
  const { setCheckedShipments, checkedShipments } =
    useShipmentSelectionContext();
  const isProd = window.location.href.includes("app");

  const showCheckbox =
    !isProd && selectedPrimaryFilter === ShipmentsPrimaryFilterType.UNASSIGNED;

  useEffect(() => {
    if (checkedShipments.length === rows.length) {
      setShowSelectAllButton(true);
    } else {
      setShowSelectAllButton(false);
    }
  }, [checkedShipments]);

  const closeInstructionModal = () => {
    setInstructions("");
    setInstructionModalOpen(false);
  };

  const onSortClick = (column: TableColumnType) => {
    const shipmentsSorting = sortFieldsTableControl as ShipmentsSorting[];
    const sortedField = shipmentsSorting.find(
      (sortField) => sortField.field === column.sortFields![0]
    );
    if (sortedField) {
      if (sortedField.direction === SortingDirection.Asc) {
        handleSortingClick(SortingDirection.Desc, column, true);
      } else {
        setSortFields([]);
      }
    } else {
      handleSortingClick(SortingDirection.Asc, column, true);
    }
  };

  const toggleAllCheckboxes = () => {
    const shipmentsNoRouting = rows
      .map((row) => row?.shipmentStatus === ShipmentStatus.Created)
      .filter(Boolean);
    if (checkedShipments.length === shipmentsNoRouting.length) {
      setCheckedShipments([]);
      setShowSelectAllButton(false);
    } else {
      const shipmentIds = rows
        .map((row) => {
          if (row && row.shipmentStatus === ShipmentStatus.Created) {
            return row.key;
          }
          return;
        })
        .filter(Boolean) as string[];
      setCheckedShipments(shipmentIds);
      setShowSelectAllButton(true);
    }
  };

  const isCheckedAll = () => {
    if (checkedShipments.length === 0) return false;
    const shipmentsNoRouting = rows
      .map((row) => row?.shipmentStatus === ShipmentStatus.Created)
      .filter(Boolean);
    return checkedShipments.length === shipmentsNoRouting.length;
  };

  const lastJob = routingJobsData?.routingJobs.edges?.[0]?.node;

  const RenderSortIcon = (column: TableColumnType) => {
    const shipmentsSorting = sortFieldsTableControl as ShipmentsSorting[];
    const sortedField = shipmentsSorting.find(
      (sortField) => sortField.field === column.sortFields![0]
    );
    if (sortedField?.direction === SortingDirection.Asc) {
      return <ArrowUpIcon className="h-4 w-4 ml-2" />;
    } else {
      return <ArrowDownIcon className="h-4 w-4 ml-2" />;
    }
  };

  return (
    <>
      <BaseModal isOpen={instructionModalOpen} onClose={closeInstructionModal}>
        <BaseModalHeader>Instruction</BaseModalHeader>
        <BaseModalBody>{instructions}</BaseModalBody>
      </BaseModal>
      <TableContainer className="grid grid-rows-[auto,1fr,50px] h-full">
        <div className="flex-init overflow-x-auto w-full">
          <div className="flex flex-col overflow-x-auto py-1 mt-2">
            <PrimaryFilters />
          </div>
          <div className="p-2 flex items-center justify-between overflow-x-auto">
            <div className="flex gap-2">
              {checkedShipments.length > 0 ? (
                <>
                  <CourieButton
                    size="xs"
                    color="purple"
                    onClick={() => {
                      if (
                        lastJob?.status === RoutingJobStatus.Pending ||
                        lastJob?.status === RoutingJobStatus.Processing ||
                        lastJob?.status === RoutingJobStatus.Ready
                      ) {
                        setModalOpen(
                          true,
                          OrdersPageModalNames.CREATE_ROUTING_JOB_WARNING
                        );
                        return;
                      }
                      setModalOpen(
                        true,
                        OrdersPageModalNames.CREATE_ROUTING_JOB
                      );
                    }}
                  >
                    <SparklesIcon className="h-4 w-4 mr-1" />
                    Optimize Routes
                  </CourieButton>
                  <CheckedShipmentsStatus
                    showSelectAllButton={showSelectAllButton}
                  />
                </>
              ) : (
                <>
                  <SortingOptionsDropdown sortFieldOptions={sortFields} />
                  <TableFilterPopover filters={filters}>
                    <OrdersFilter />
                  </TableFilterPopover>
                  <div className="w-48">
                    <LayersManager zIndex={200}>
                      <DriverFilterSelector
                        driverId={selectedDriverIdFilter}
                        setDriverId={setSelectedDriverIdFilter}
                      />
                    </LayersManager>
                  </div>
                  <div className="w-48">
                    <SearchShipmentsInput />
                  </div>
                </>
              )}
            </div>
            <div className="min-w-max flex">
              <div className="">
                <RoutingJobsStatusButtons />
              </div>
              <LayersManager zIndex={200}>
                <DateBasedPaginator
                  minDate={minDate}
                  setMinDate={setMinDate}
                  maxDate={maxDate}
                  setMaxDate={setMaxDate}
                />
              </LayersManager>
            </div>
          </div>
        </div>
        <Loading loading={shipmentsApiLoading} className="grow overflow-x-auto">
          {shipmentsApiResponse?.shipments?.edges?.length !== 0 &&
          !shipmentsApiLoading ? (
            <table className="w-full relative">
              <tr className="sticky top-0 bg-white z-10">
                {showCheckbox && (
                  <th className="bg-slate-50 text-xs font-semibold uppercase text-gray-800 p-2 relative">
                    <Checkbox
                      defaultChecked={false}
                      checked={isCheckedAll()}
                      className="form-checkbox h-5 w-5 left-3 top-1.5 absolute"
                      onChange={() => {
                        toggleAllCheckboxes();
                      }}
                    />
                  </th>
                )}
                {SHIPMENT_TABLE_COLUMNS.map((column: TableColumnType, i) => (
                  <th
                    className={classNames({
                      "bg-slate-50 text-xs font-semibold uppercase text-gray-800 py-2":
                        true,
                      truncate: true,
                      [SORTED_COLUMN_BG]:
                        column.sortFields && areFieldsSorted(column.sortFields),
                    })}
                    key={column.key}
                  >
                    <div
                      className={classNames({
                        flex: true,
                        "hover:underline hover:cursor-pointer":
                          !!column.sortFields,
                        "px-4": true,
                      })}
                      onClick={() => column.sortFields && onSortClick(column)}
                    >
                      <span className="my-auto h-full">{column.title}</span>
                      {column.sortFields &&
                        areFieldsSorted(column.sortFields) &&
                        RenderSortIcon(column)}
                    </div>
                  </th>
                ))}
              </tr>
              {rows.map((row: ShipmentRow) => (
                <OrderRow row={row} key={row?.key} shipmentId={row?.key} />
              ))}
            </table>
          ) : (
            <div
              className="w-full text-center text-gray-400 p-4 flex items-center justify-center"
              style={{ height: 200 }}
            >
              <span>No orders found with provided filters. 🐝 </span>
            </div>
          )}
        </Loading>
        <div>{!shipmentsApiLoading && <ShipmentsTableFooter />}</div>
      </TableContainer>
    </>
  );
}

export default ShipmentsTable;
