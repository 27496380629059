import { ShipmentStatus } from "@api/graphql/generated/generated-types";
import {
  NewspaperIcon,
  ArrowsUpDownIcon,
  UserMinusIcon,
  PencilSquareIcon,
  ArrowDownIcon,
  ArrowDownTrayIcon,
} from "@heroicons/react/24/outline";
import { ShipmentRow } from "../types";

export function hoverActions(row: ShipmentRow) {
  return [
    {
      key: "edit",
      label: "Edit",
      icon: <PencilSquareIcon className="w-5 h-5 text-slate-700" />,
      disabled:
        row?.status === ShipmentStatus.Completed ||
        row?.status === ShipmentStatus.InTransit,
      tooltip: undefined,
    },
    {
      key: "viewPod",
      label: "View POD",
      icon: <NewspaperIcon className="w-5 h-5 text-slate-700" />,
      disabled: row?.artifactsAtStops.length === 0,
      tooltip:
        row?.artifactsAtStops.length === 0 ? "No POD available" : undefined,
    },
    {
      key: "changeRouting",
      label: "Change Routing",
      icon: <ArrowsUpDownIcon className="w-5 h-5 text-slate-700" />,
      disabled: !row?.canChangeRouting,
      tooltip: !row?.canChangeRouting
        ? `The order is in ${row?.status} status.`
        : undefined,
    },
    {
      key: "unassignDriver",
      label: "Unassign Driver",
      icon: <UserMinusIcon className="w-5 h-5 text-slate-700" />,
      disabled: !row?.canUnassignDriver,
      tooltip: !row?.canUnassignDriver
        ? `The order is in ${row?.status} status.`
        : undefined,
    },
    {
      key: "downloadOrderPdf",
      label: "Download Order PDF",
      icon: <ArrowDownTrayIcon className="w-5 h-5 text-slate-700" />,
      disabled: false,
      tooltip: `Download PDF`,
    },
  ];
}
